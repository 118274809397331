import { ILookupKey } from '@sharedModels/lookup-key';

export enum LookupType {
  ExitCodes,
  ReferralTypes,
  ZeroToTen,
  Counties,
  Countries, 
  Districts,
  Selpas,
  Genders,
  Schools,
  Teachers,
  States,
  GuardianRelationships,
  EnglishProficiencies,
  Ethnicities,
  Frequencies,
  Forms,
  GradeLevels,
  InterventionStatus,
  LanguageProficiencies,
  Languages,
  MeetingPurposes,
  MeetingAccommodations,
  OutcomeInterventions,
  ProviderTypes,
  Races,
  StudentStatuses,
  SubjectAreas,
  FormStatus,
  StudentEventTypes,
  Suffix,
  PrimaryResidence,
  // Users and Permissions
  Roles,
  Permissions,
  UserTypes,
  // PreSST Form
  PreSstGenChecklists,
  PreSstAlterationInstructions,
  PreSstBehavioralInterventions,
  PreSstCurricularAccoms,
  PreSstEnvironmentalAccoms,
  PreSstHomeChecklists,
  PreSstHomeworkInterventions,
  PreSstInstructionalOutputs,
  PreSstModifiedAssignments,
  // ELL Supplemental Support
  EllLanguagePrograms,
  // Affirm
  AffirmReasons,
  // 504 Eligibility
  EligibilityBehaviors,
  EligibilityInterviews,
  EligibilityObservations,
  EligibilityRecordAcademics,
  EligibilityRecordReviews,
  EligibilityMeetingPurpose,
  EligibilityTeamDetermined,
  // 504 Accommodations
  AccommodationGrading,
  AccommodationInstructional,
  AccommodationMaterials,
  AccommodationTests,
  AccommodationPurposeOfMeeting,
  AccommodationEnvironment,
  ServiceFrequencyX,
  ElpacListening,
  ElpacListeningAccom,
  ElpacListeningMod,
  ElpacReading,
  ElpacReadingAccom,
  ElpacReadingMod,
  ElpacSpeaking,
  ElpacSpeakingAccom,
  ElpacSpeakingMod,
  ElpacWriting,
  ElpacWritingAccom,
  ElpacWritingMod,
  StarTestingEnglish,
  StarTestingMath,
  StarTestingScience,
  StarTestingDrdp,
  StarTestingFitness,
  StsMathAccom,
  StsMathDes,
  StsReadingAccom,
  StsReadingDes,
  SubjectAreasAccom,
  EnglishAccomEmbedded,
  EnglishAccomNonEmbedded,
  EnglishAccomSupportsEmbedded,
  EnglishAccomSupportsNonEmbedded,
  MathAccomEmbedded,
  MathAccomNonEmbedded,
  MathAccomSupportsEmbedded,
  MathAccomSupportsNonEmbedded,
  ScienceAccomEmbedded,
  ScienceAccomNonEmbedded,
  ScienceAccomSupportsEmbedded,
  ScienceAccomSupportsNonEmbedded,
  PhysicalFitnessAccommodation,
  // SST Referral
  ReferralStrengths,
  ReferralMedicalConcerns,
  ReferralLanguageConcerns,
  ReferralLearningConcerns,
  ReferralBehaviorConcerns,
  ReferralPreviousConcerns,
  ReferralLearningStyleConcerns,
  ReferralMotorConcerns,
  ReferralAcademicConcerns,
  ReferralInputTypes,
  // Unknown
  InterventionPlanInterventions,
  InterventionPlanDeliveries,
  BipTypes,
  EvaluationTypes,
  ParentRelation,
  ProgressMonitoringOutcomes,
  MeetingInvitationPurpose,
  MeetingInvitationReturn,
  //Intervention Plan Review
  SuccessOfIntervention,
  ProgressMonitoringFrequencies,
  //Data Review Team
  DataReviewProviders,
  //Integration
  IntegrationFileFormats,
  SSTMeetingPurposes,
}

export const MasterLookupKeys: ILookupKey[] = [
  { endpoint: 'exitCodes', lookup: LookupType.ExitCodes },
  { endpoint: 'integration-file-formats', lookup: LookupType.IntegrationFileFormats },
  { endpoint: 'referraltypes', lookup: LookupType.ReferralTypes },
  { endpoint: 'zeroToTen', lookup: LookupType.ZeroToTen },
  { endpoint: 'counties', lookup: LookupType.Counties },
  { endpoint: 'countries', lookup: LookupType.Countries },
  { endpoint: 'districts', lookup: LookupType.Districts },
  { endpoint: 'selpas', lookup: LookupType.Selpas },
  { endpoint: 'genders', lookup: LookupType.Genders },
  { endpoint: 'schools', lookup: LookupType.Schools },
  { endpoint: 'teachers', lookup: LookupType.Teachers },
  { endpoint: 'englishProficiencies', lookup: LookupType.EnglishProficiencies },
  { endpoint: 'ethnicities', lookup: LookupType.Ethnicities },
  { endpoint: 'frequencies', lookup: LookupType.Frequencies },
  { endpoint: 'progressMonitoringFrequencies', lookup: LookupType.ProgressMonitoringFrequencies },
  { endpoint: 'forms', lookup: LookupType.Forms },
  { endpoint: 'gradeLevels', lookup: LookupType.GradeLevels },
  { endpoint: 'interventionStatus', lookup: LookupType.InterventionStatus },
  { endpoint: 'languageProficiencies', lookup: LookupType.LanguageProficiencies },
  { endpoint: 'languages', lookup: LookupType.Languages },
  { endpoint: 'meetingPurposes', lookup: LookupType.MeetingPurposes },
  { endpoint: 'meetingAccommodations', lookup: LookupType.MeetingAccommodations },
  { endpoint: 'outcomeInterventions', lookup: LookupType.OutcomeInterventions },
  { endpoint: 'providerTypes', lookup: LookupType.ProviderTypes },
  { endpoint: 'races', lookup: LookupType.Races },
  { endpoint: 'studentStatuses', lookup: LookupType.StudentStatuses },
  { endpoint: 'subjectAreas', lookup: LookupType.SubjectAreas },
  { endpoint: 'formStatus', lookup: LookupType.FormStatus },
  { endpoint: 'studentEventTypes', lookup: LookupType.StudentEventTypes },
  { endpoint: 'states', lookup: LookupType.States },
  { endpoint: 'relationships', lookup: LookupType.GuardianRelationships },
  { endpoint: 'suffix', lookup: LookupType.Suffix },
  { endpoint: 'primaryResidence', lookup: LookupType.PrimaryResidence },
  // Users and Permissions
  { endpoint: 'roles', lookup: LookupType.Roles },
  { endpoint: 'permissions', lookup: LookupType.Permissions },
  { endpoint: 'userTypes', lookup: LookupType.UserTypes },
  // PreSST Form Lookups
  { endpoint: 'presstGenChecklists', lookup: LookupType.PreSstGenChecklists },
  { endpoint: 'presstAlterationInstructions', lookup: LookupType.PreSstAlterationInstructions },
  { endpoint: 'presstBehavioralInterventions', lookup: LookupType.PreSstBehavioralInterventions },
  { endpoint: 'presstCurricularAccoms', lookup: LookupType.PreSstCurricularAccoms },
  { endpoint: 'presstEnvironmentalAccoms', lookup: LookupType.PreSstEnvironmentalAccoms },
  { endpoint: 'presstHomeChecklists', lookup: LookupType.PreSstHomeChecklists },
  { endpoint: 'presstHomeworkInterventions', lookup: LookupType.PreSstHomeworkInterventions },
  { endpoint: 'presstInstructionalOutputs', lookup: LookupType.PreSstInstructionalOutputs },
  { endpoint: 'presstModifiedAssignments', lookup: LookupType.PreSstModifiedAssignments },
  // ELL Supplemental Support
  { endpoint: 'languagePrograms', lookup: LookupType.EllLanguagePrograms },
  // Affirm
  { endpoint: 'affirmReasons', lookup: LookupType.AffirmReasons },
  // 504 Eligibility
  { endpoint: 'eligibilityBehaviors', lookup: LookupType.EligibilityBehaviors },
  { endpoint: 'eligibilityInterviews', lookup: LookupType.EligibilityInterviews },
  { endpoint: 'eligibilityObservations', lookup: LookupType.EligibilityObservations },
  { endpoint: 'eligibilityRecordAcademics', lookup: LookupType.EligibilityRecordAcademics },
  { endpoint: 'eligibilityRecordReviews', lookup: LookupType.EligibilityRecordReviews },
  { endpoint: 'eligibilityMeetingPurpose', lookup: LookupType.EligibilityMeetingPurpose },
  { endpoint: 'eligibilityTeamDetermined', lookup: LookupType.EligibilityTeamDetermined },
  // Unknown
  { endpoint: 'interventionPlanInterventions', lookup: LookupType.InterventionPlanInterventions },
  { endpoint: 'interventionPlanDeliveries', lookup: LookupType.InterventionPlanDeliveries },
  { endpoint: 'bipTypes', lookup: LookupType.BipTypes },
  { endpoint: 'evaluationTypes', lookup: LookupType.EvaluationTypes },
  { endpoint: 'parentRelation', lookup: LookupType.ParentRelation },
  { endpoint: 'progressMonitoringOutcomes', lookup: LookupType.ProgressMonitoringOutcomes },
  { endpoint: 'meetingInvitationPurpose', lookup: LookupType.MeetingInvitationPurpose },
  { endpoint: 'meetingInvitationReturn', lookup: LookupType.MeetingInvitationReturn},
  // 504 Accommodation Plan
  { endpoint: 'elpacListening', lookup: LookupType.ElpacListening },
  { endpoint: 'elpacListeningAccom', lookup: LookupType.ElpacListeningAccom },
  { endpoint: 'elpacListeningMod', lookup: LookupType.ElpacListeningMod },
  { endpoint: 'elpacReading', lookup: LookupType.ElpacReading },
  { endpoint: 'elpacReadingAccom', lookup: LookupType.ElpacReadingAccom },
  { endpoint: 'elpacReadingMod', lookup: LookupType.ElpacReadingMod },
  { endpoint: 'elpacSpeaking', lookup: LookupType.ElpacSpeaking },
  { endpoint: 'elpacSpeakingAccom', lookup: LookupType.ElpacSpeakingAccom },
  { endpoint: 'elpacSpeakingMod', lookup: LookupType.ElpacSpeakingMod },
  { endpoint: 'elpacWriting', lookup: LookupType.ElpacWriting },
  { endpoint: 'elpacWritingAccom', lookup: LookupType.ElpacWritingAccom },
  { endpoint: 'elpacWritingMod', lookup: LookupType.ElpacWritingMod },
  { endpoint: 'accommodationEnvironment', lookup: LookupType.AccommodationEnvironment },
  { endpoint: 'serviceFrequencyX', lookup: LookupType.ServiceFrequencyX },
  { endpoint: 'accommodationGrading', lookup: LookupType.AccommodationGrading },
  { endpoint: 'accommodationInstructional', lookup: LookupType.AccommodationInstructional },
  { endpoint: 'accommodationMaterials', lookup: LookupType.AccommodationMaterials },
  { endpoint: 'accommodationTests', lookup: LookupType.AccommodationTests },
  { endpoint: 'accommodationPurposeOfMeeting', lookup: LookupType.AccommodationPurposeOfMeeting },
  { endpoint: 'starTestingEnglish', lookup: LookupType.StarTestingEnglish },
  { endpoint: 'starTestingMath', lookup: LookupType.StarTestingMath },
  { endpoint: 'starTestingScience', lookup: LookupType.StarTestingScience },
  { endpoint: 'starTestingDrdp', lookup: LookupType.StarTestingDrdp },
  { endpoint: 'starTestingFitness', lookup: LookupType.StarTestingFitness },
  { endpoint: 'stsMathAccom', lookup: LookupType.StsMathAccom },
  { endpoint: 'stsMathDes', lookup: LookupType.StsMathDes },
  { endpoint: 'stsReadingAccom', lookup: LookupType.StsReadingAccom },
  { endpoint: 'stsReadingDes', lookup: LookupType.StsReadingDes },
  { endpoint: 'subjectAreasAccom', lookup: LookupType.SubjectAreasAccom },
  { endpoint: 'englishAccomEmbedded', lookup: LookupType.EnglishAccomEmbedded },
  { endpoint: 'englishAccomNonEmbedded', lookup: LookupType.EnglishAccomNonEmbedded },
  { endpoint: 'englishAccomSupportsEmbedded', lookup: LookupType.EnglishAccomSupportsEmbedded },
  { endpoint: 'englishAccomSupportsNonEmbedded', lookup: LookupType.EnglishAccomSupportsNonEmbedded },
  { endpoint: 'mathAccomEmbedded', lookup: LookupType.MathAccomEmbedded },
  { endpoint: 'mathAccomNonEmbedded', lookup: LookupType.MathAccomNonEmbedded },
  { endpoint: 'mathAccomSupportsEmbedded', lookup: LookupType.MathAccomSupportsEmbedded },
  { endpoint: 'mathAccomSupportsNonEmbedded', lookup: LookupType.MathAccomSupportsNonEmbedded },
  { endpoint: 'scienceAccomEmbedded', lookup: LookupType.ScienceAccomEmbedded },
  { endpoint: 'scienceAccomNonEmbedded', lookup: LookupType.ScienceAccomNonEmbedded },
  { endpoint: 'scienceAccomSupportsEmbedded', lookup: LookupType.ScienceAccomSupportsEmbedded },
  { endpoint: 'scienceAccomSupportsNonEmbedded', lookup: LookupType.ScienceAccomSupportsNonEmbedded },
  { endpoint: 'physicalFitnessAccommodation', lookup: LookupType.PhysicalFitnessAccommodation },

  // SST Referral
  { endpoint: 'referralStrengths', lookup: LookupType.ReferralStrengths },
  { endpoint: 'referralMedicalConcerns', lookup: LookupType.ReferralMedicalConcerns },
  { endpoint: 'referralLanguageConcerns', lookup: LookupType.ReferralLanguageConcerns },
  { endpoint: 'referralLearningConcerns', lookup: LookupType.ReferralLearningConcerns },
  { endpoint: 'referralBehaviorConcerns', lookup: LookupType.ReferralBehaviorConcerns },
  { endpoint: 'referralPreviousConcerns', lookup: LookupType.ReferralPreviousConcerns },
  { endpoint: 'referralLearningStyleConcerns', lookup: LookupType.ReferralLearningStyleConcerns },
  { endpoint: 'referralMotorConcerns', lookup: LookupType.ReferralMotorConcerns },
  { endpoint: 'referralAcademicConcerns', lookup: LookupType.ReferralAcademicConcerns },
  { endpoint: 'referralInputTypes', lookup: LookupType.ReferralInputTypes },

  //Intervention Plan Review
  { endpoint: 'successOfIntervention', lookup: LookupType.SuccessOfIntervention },
  //Data Review 
  { endpoint: 'dataReviewProviders', lookup: LookupType.DataReviewProviders },
  //SST Meeting Invitation and Notice
  { endpoint: 'sstMeetingPurposes', lookup: LookupType.SSTMeetingPurposes }
  
]
